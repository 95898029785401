.resume_container {
  min-height: 100vh;
  position: relative;
  color: var(--main-text-color);
  padding-top: 132.1719px;
  padding-left: 20px;
  padding-right: 20px;
}

.resume_container .header-container {
  position: relative;
}

.resume_container .section-title::after {
  left: 11rem;
  top: 50%;
}
.resume_container .resume-item {
  margin-bottom: 50px;
}
.resume_container .resume-item .title {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 30px;
}

.resume-item .title .icon {
  width: 50px;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #ffc700;
  border-radius: 8px;
}

.resume-item .title .label {
  font-size: 2rem;
}
.resume-item .content-row {
  display: flex;
  flex-flow: row wrap;
  min-width: 0;
  margin-left: -8px;
  margin-right: -8px;
  row-gap: 16px;
}

.resume-item .content-col {
  display: block;
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
  padding-left: 8px;
  padding-right: 8px;
}

.card-resume {
  background-color: var(--shape-bg);
  border-radius: 12px;
  overflow: hidden;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.card-resume .year {
  color: #6f6f6f;
  font-weight: 600;
}

.card-resume .position {
  font-size: 1.5rem;
  font-weight: 500;
  margin-top: 15px;
  margin-bottom: 15px;
  flex: 1 1 auto;
}

.card-resume .institution {
  font-size: 1.2rem;
  font-weight: 500;
}

.card-resume .location {
  font-size: 1.1rem;
}
@media only screen and (max-width: 992px) {
  .resume_container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .resume_container .content-row .content-col {
    max-width: 50%;
    flex: 0 0 50%;
  }
  .resume_container .card-resume {
    height: 100%;
  }
}
@media only screen and (max-width: 768px) {
  .resume_container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .resume_container .content-row .content-col {
    max-width: 50%;
    flex: 0 0 50%;
  }
  .resume_container .card-resume {
    height: 100%;
  }
}

@media only screen and (max-width: 576px) {
  .resume_container {
    padding-left: 10px;
    padding-right: 10px;
  }
  .resume_container .header-container .section-title {
    padding-left: 10px;
  }
  .resume_container .header-container .section-title::after {
    top: 50%;
    left: 8rem;
  }
  .resume_container .resume-item .title {
    padding-left: 10px;
    margin-bottom: 20px;
  }

  .resume_container .resume-item .title .icon {
    width: 40px;
    height: 40px;
  }
  .resume_container .resume-item .title .label {
    font-size: 1.3rem;
  }
  .resume_container .content-row {
    display: flex;
    flex-direction: column;
    padding: 10px;
  }
  .resume_container .content-row .content-col {
    max-width: 100%;
    flex: 1 1 auto;
  }

  .content-row .card-resume .year {
    font-size: 0.9rem;
  }
  .content-row .card-resume .position {
    font-size: 1.2rem;
  }
  .content-row .card-resume .institution {
    font-size: 1rem;
  }
  .content-row .card-resume .location {
    font-size: 0.9rem;
  }
}
