:root {
  --btn-menu-bg: #e8e8e8;
  --shape-bg: #f1faff;
  --main-bg: #fff;
  --main-text-color: #000;
  --text-muted: #737373;
  --border-muted: #c3dbe8;
  --tw-gradient-from: #fa5252;
  --tw-gradient-to: #dd2476;
  --secondary-text-color: #0f0f0f;
  --max-width-1: 1240px;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  --bg-filter-blur-1: 255 255 255;
  --border-filter-blur-1: 240 240 240;
}

.dark {
  --btn-menu-bg: #585858;
  --shape-bg: #34353a;
  --main-bg: #1d1b1b;
  --text-muted: #afafaf;
  --border-muted: #414249;
  --main-text-color: #fff;
  --secondary-text-color: #f0f0f0;
  --bg-filter-blur-1: 29 27 27;
  --border-filter-blur-1: 52 53 58;
}

body {
  box-sizing: border-box;
  margin: 0;
}

.app-container {
  background-color: var(--main-bg);
  min-height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;
}

.app-innner-container {
  max-width: var(--max-width-1);
  margin: auto;
  z-index: 3;
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.background-shape {
  position: absolute;
  width: 76%;
  top: 0;
  right: 0;
  height: calc(100vh + 0px);
  background-color: var(--shape-bg);
  clip-path: polygon(100% 0%, 100% 100%, 20% 86%, 0% 0%);
}
.header-container {
  position: relative;
}
.section-title {
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: 50px;
}

.section-title::after {
  content: "";
  top: 11.3rem;
  left: 9rem;
  width: 15rem;
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
  border-radius: 0.375rem;
  height: 0.25rem;
  position: absolute;
}

.btn-arrow-up {
  background-color: #ffb324;
}

@media only screen and (max-width: 576px) {
  .section-title {
    font-size: 1.5rem;
  }
  .section-title::after {
    top: 50%;
    left: 5rem;
    width: 50%;
  }
}
