.Footer_container {
  width: 100vw;
  background-color: var(--shape-bg);
  position: static;
  bottom: 0;
  left: 0;
  border-top: 1px solid var(--border-muted);
}

.Footer_container .inner-container {
  height: auto;
  display: flex;
  justify-content: space-between;
  color: var(--main-text-color);
  padding: 20px 30px;
  max-width: var(--max-width-1);
  margin: auto;
}
.Footer_container .right .social-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

@media only screen and (max-width: 768px) {
  .Footer_container .inner-container {
    font-size: 1rem;
    padding-right: 30px;
    padding-left: 30px;
    flex-direction: column;
    gap: 10px;
  }
  .Footer_container .right .social-container {
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 576px) {
  .Footer_container .inner-container {
    font-size: 0.9rem;
    padding-right: 20px;
    padding-left: 20px;
  }
}
