.portfolio_container {
  min-height: 100vh;
  position: relative;
  color: var(--main-text-color);
  padding-top: 132.1719px;
  padding-left: 20px;
  padding-right: 20px;
}

.portfolio_container .section-title {
  border-bottom: 2px dashed var(--main-text-color);
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.portfolio_container .section-title .title-hilight {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.portfolio_container .section-title::after {
  display: none;
}

.portfolio_container .section-subtitle {
  color: var(--text-muted);
  font-size: 1.3rem;
  margin-bottom: 20px;
}
.porto-card {
  background-color: var(--shape-bg);
}
.porto-content .btn-action {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
