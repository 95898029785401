.Content_container {
  flex: 1 1 auto;
}

.hero_content {
  min-height: calc(100vh);
  padding-top: 172.1719px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.hero_content .inner-container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 16px;
  padding-left: 16px;
}

.hero_content .main-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hero_content .main-info .left {
  width: 65%;
}
.hero_content .main-info .right {
  width: 35%;
}

.hero_content .mini-title {
  font-size: 2rem;
}
.hero_content .subtitle {
  font-size: 1.6rem;
  font-weight: 500;
}
.hero_content .mini-title {
  font-weight: 600;
  color: #ffb324;
}
.hero_content .title {
  font-size: 3.5rem;
  font-weight: 700;
  color: var(--main-text-color);
}
.hero_content .title .title-name {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.hero_content .running-title {
  font-size: 48px;
  font-weight: 700;
  line-height: 1.2;
  display: flex;
  gap: 10px;
  color: var(--secondary-text-color);
  margin-bottom: 20px;
}

.hero_content .subtitle {
  color: var(--secondary-text-color);
}

.hero_content .call-to-action {
  margin: 3rem 0px;
}
.hero_content .call-to-action a,
.hero_content .call-to-action button {
  padding: 0.625rem 1.25rem;
  background-color: var(--btn-menu-bg);
  color: var(--main-text-color);
  border-radius: 0.375rem;
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  font-weight: 600;
  cursor: pointer;
}

.hero_content .call-to-action a:hover,
.hero_content .call-to-action button:hover {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.hero_content .call-to-action .btn-left {
  margin-right: 0.625rem;
}

.hero_content .call-to-action .btn-right {
  margin-left: 0.625rem;
}

.hero_content .running-title span {
  padding: 0;
}

.hero_content .running-title .text-running {
  overflow: hidden;
  vertical-align: middle;
  position: relative;
  text-align: left;
}

.hero_content .running-title .text-running::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  width: 3px;
  height: 80%;
  background-color: rgba(75, 75, 75, 0.5);
  transform: translateY(-50%);
}

.dark .hero_content .running-title .text-running::after {
  background-color: rgba(255, 255, 255, 0.5);
}

.hero_content .running-title .text-running b {
  display: inline-block;
  position: absolute;
  white-space: nowrap;
  left: 0;
  top: 0;
}

.hero_content .running-title .text-running .is-hidden {
  opacity: 0;
}

.hero_content .image-container {
  position: relative;
}
.hero_content .image-container img {
  position: relative;
  min-width: 100%;
  opacity: 0;
}
.hero_content .image-container .main {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  border-radius: 12px;
}
.hero_content .image-container .main::before {
  position: absolute;
  content: "";
  top: 0px;
  bottom: 0px;
  left: 25px;
  right: 25px;
  background-color: #ffb324;
  z-index: -1;
  transform: rotate(-50deg);
  animation: animeRotate 13s linear 0s infinite alternate;
}

@media only screen and (max-width: 992px) {
  .hero_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .hero_content .inner-container {
    padding-right: 2rem;
    padding-left: 2rem;
  }
  .hero_content .main-info .right {
    padding: 0;
    display: flex;
    justify-content: flex-end;
    width: 40%;
  }
  .hero_content .main-info .left {
    width: 60%;
  }
  .hero_content .image-container {
    max-width: 350px;
  }
}

@media only screen and (max-width: 768px) {
  .hero_content {
    padding-top: 150px;
  }
  .hero_content .main-info {
    flex-direction: column;
  }
  .hero_content .main-info .left,
  .hero_content .main-info .right {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .hero_content .main-info .right {
    padding: 3rem;
    order: 1;
  }
  .hero_content .main-info .left {
    padding: 0;
    order: 2;
  }

  .hero_content .left .title {
    font-size: 2.2rem;
  }
  .hero_content .left .subtitle {
    font-size: 1.3rem;
    text-align: center;
  }
  .hero_content .left .running-title {
    font-size: 2.3rem;
  }

  .right .image-container {
    max-width: 300px;
  }
}

@media only screen and (max-width: 576px) {
  .hero_content {
    padding-top: 0px;
  }
  .hero_content .call-to-action {
    display: flex;
    margin: 1rem 0px;
  }
  .hero_content .call-to-action .btn-left,
  .hero_content .call-to-action .btn-right {
    font-size: 0.8rem;
  }
  .hero_content .mini-title {
    font-size: 1.2rem;
  }

  .right .image-container {
    max-width: 200px;
  }
  .hero_content .left .title {
    font-size: 1.5rem;
  }
  .hero_content .left .running-title {
    font-size: 1.3rem;
  }
  .hero_content .left .subtitle {
    font-size: 1rem;
    text-align: center;
  }
}

@keyframes animeRotate {
  0% {
    transform: rotate(-50deg);
  }
  100% {
    transform: rotate(-30deg);
  }
}
