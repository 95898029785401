.about_container {
  min-height: 100vh;
  position: relative;
  color: var(--main-text-color);
  padding-top: 152.1719px;
  padding-left: 20px;
  padding-right: 20px;
}
.about_container .header-container .section-title::after {
  top: 50%;
}

.about_container .content-container {
  display: flex;
  justify-content: space-between;
  gap: 100px;
}

.about_container .container-left .img-container img {
  max-width: 350px;
  border-radius: 12px;
}

.about_container .img-container {
  position: relative;
}
.about_container .container-left .shape::before {
  position: absolute;
  background-color: #ffb324;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  transform: translate(30px, 30px);
  border-radius: 12px;
}
.container-right .intro {
  margin-bottom: 20px;
}
.container-right .intro h2,
.container-right .info h2 {
  font-size: 2rem;
  font-weight: 600;
  color: var(--main-text-color);
  margin-bottom: 10px;
}

.container-right .intro h3,
.container-right .info h3 {
  font-size: 1.7rem;
  font-weight: 600;
  margin-bottom: 10px;
  color: var(--main-text-color);
}

.container-right .intro p {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 10px;
  color: var(--text-muted);
}

.container-right .info-contact {
  display: flex;
  justify-content: space-between;
}

.container-right .info-contact .contact {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.container-right .info-contact .contact .icon {
  background-color: #ffb324;
  width: 50px;
  height: 50px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-right .info-contact .content .label {
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--text-muted);
}

.container-right .info-contact .content .text {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--main-text-color);
}

@media only screen and (max-width: 992px) {
  .about_container {
    padding-right: 20px;
    padding-left: 20px;
  }
  .about_container .header-container .section-title::after {
    top: 50%;
  }
  .about_container .content-container {
    gap: 0px;
  }
  .about_container .content-container .container-left {
    width: 40%;
  }
  .about_container .content-container .container-right {
    width: 60%;
  }
  .about_container .content-container .container-left .img-container {
    max-width: 250px;
  }
  .about_container .container-left .img-container img {
    max-width: 100%;
  }
  .container-right .intro p {
    font-size: 1.2rem;
  }
  .container-right .info h3 {
    font-size: 1.3rem;
  }
  .container-right .info .info-contact .contact .content .label,
  .container-right .info .info-contact .contact .content .text {
    font-size: 1rem;
  }
}
/* md */
@media only screen and (max-width: 768px) {
  .about_container {
    padding: 0px 20px;
  }
  .header-container .section-title {
    font-size: 1.8rem;
  }
  .header-container .section-title::after {
    top: 50%;
    left: 6rem;
  }
  .about_container .content-container {
    display: flex;
    flex-direction: column;
    gap: 50px;
  }
  .content-container .container-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .content-container .container-left .img-container {
    max-width: 300px;
  }
  .img-container img {
    width: 100%;
  }
  .about_container .content-container .container-right,
  .about_container .content-container .container-left {
    width: 100%;
  }
  .content-container .container-right .intro h2 {
    font-size: 1.9rem;
  }

  .content-container .container-right .intro h3 {
    font-size: 1.7rem;
  }
  .content-container .container-right .intro p {
    font-size: 1.5rem;
  }

  .container-right .info h3 {
    font-size: 1.6rem;
  }
  .container-right .info .info-contact {
    display: flex;
    flex-direction: row;
  }
  .container-right .info .info-contact .contact {
    display: flex;
    justify-content: flex-start;
    width: 50%;
  }
  .container-right .info-contact .contact .icon {
    width: 50px;
    height: 50px;
  }
  .container-right .info-contact .contact .content .label,
  .container-right .info-contact .contact .content .text {
    font-size: 1.2rem;
  }
}

@media only screen and (max-width: 576px) {
  .about_container {
    padding: 0px 10px;
  }
  .about_container .content-container {
    display: flex;
    flex-direction: column;
    gap: 50px;
  }
  .about_container .content-container .container-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .content-container .container-left .img-container {
    max-width: 200px;
  }
  .img-container img {
    width: 100%;
  }
  .about_container .content-container .container-right {
    width: 100%;
  }
  .content-container .container-right .intro h2 {
    font-size: 1.5rem;
  }

  .content-container .container-right .intro h3 {
    font-size: 1.3rem;
  }
  .content-container .container-right .intro p {
    font-size: 1rem;
  }

  .container-right .info h3 {
    font-size: 1.3rem;
  }
  .container-right .info .info-contact {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .container-right .info .info-contact .contact {
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }
  .container-right .info-contact .contact .icon {
    width: 40px;
    height: 40px;
  }
  .container-right .info-contact .contact .content .label,
  .container-right .info-contact .contact .content .text {
    font-size: 1rem;
  }
}
