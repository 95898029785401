@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "Manrope";
    font-display: swap;
    src: url("../../../assets/fonts/manrope/Manrope-VariableFont_wght.ttf") format("truetype");
        
}

html{
    @apply scroll-smooth
}

body {
    font-family: 'Manrope', sans-serif;
}

.page-container {
    @apply flex flex-col min-h-screen
}

// --- Start Header --- //
.navbar-fixed{
    @apply fixed z-[9999] bg-white bg-opacity-70 border-b-borderLight border-b-[1px] backdrop-blur-sm dark:bg-bgDarkThree dark:border-b-bgDarkTwo dark:bg-opacity-70;
}

.hamburger-line{
    @apply w-[30px] h-[2px] my-2 block bg-dark dark:bg-white
}

.hamburger-active > span:nth-child(1){
    @apply rotate-45;
}
.hamburger-active > span:nth-child(2){
    @apply scale-0;
}
.hamburger-active > span:nth-child(3){
    @apply -rotate-45;
}
// --- End Header --- //

// --- Start Main Content --- //
.page-container .main-content{
    @apply flex-auto h-full
}
// --- End Main Content --- //

// --- Start Footer --- //
.footer-container{
    @apply w-full py-4 px-4 border-t-borderLight border-t-[1px] bg-bgWhite dark:bg-bgDarkOne dark:border-t-bgDarkThree;

    .copy-right{
        @apply dark:text-white;
        i{
            @apply text-rose-400;
        }
    }
}
// --- End Footer --- //

// toogle

#theme-toggle:checked ~ label {
    div.toggle-dark {
        @apply bg-white;
        i, svg{
            @apply text-primary
        }
    }
    div.toggle-light {
        @apply bg-none;
        i, svg{
            @apply text-textMain
        }
    }
    
} 
