.contact_container {
  min-height: 100vh;
  position: relative;
  color: var(--main-text-color);
  padding-top: 132.1719px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 2rem;
}

.contact_container .section-title {
  border-bottom: 2px dashed var(--main-text-color);
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.contact_container .section-title .title-hilight {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.contact_container .section-title::after {
  display: none;
}

.contact_container .section-subtitle {
  color: var(--text-muted);
  font-size: 1.3rem;
  margin-bottom: 20px;
}

.contact_container .contact-wrapper {
  display: flex;
}

.contact_container .contact-wrapper .col-4 {
  width: 33.333333333%;
  padding: 10px;
}

.contact_container .contact-wrapper .col-8 {
  width: 66.666666664%;
  padding: 10px;
}

.list-contact {
  margin: 20px 0px;
  padding: 10px 20px;
  height: 50px;
  display: flex;
  gap: 10px;
  width: 100%;
  background-color: var(--shape-bg);
  align-items: center;
  font-size: 1.2rem;
  border-radius: 8px;
}
.list-contact .contact-icon {
  color: #ffb324;
}

.list-contact span button:hover,
.list-contact span a:hover {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.contact-wrapper .btn-contact-submit {
  width: 100%;
  height: 50px;
  border-radius: 8px;
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  margin-top: 10px;
  font-size: 1.2rem;
}

@media only screen and (max-width: 992px) {
  .contact_container .contact-wrapper {
    display: flex;
    flex-direction: column;
  }
  .contact_container .contact-wrapper .col-8,
  .contact_container .contact-wrapper .col-4 {
    width: 100%;
  }

  .contact-wrapper .form-contact {
    width: 100%;
    max-width: none;
  }
}
