.Header_container {
  width: 100%;
  padding: 20px 0px;
}

.Header_container .header-inner {
  width: 100%;
  display: flex;
  align-items: center;
  max-width: var(--max-width-1);
  margin: auto;
}

.Header_container .logo {
  padding-left: 1.25rem;
}

.Header_container .logo > img {
  height: 40px;
}

.Header_container .nav-menu {
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  justify-content: flex-end;
}

.Header_container .nav-menu > ul {
  display: flex;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.nav-menu > ul > li > a,
.config-action .btn-config {
  margin-left: 0.625rem;
  margin-right: 0.625rem;
  padding: 0.625rem 1.25rem;
  background-color: var(--btn-menu-bg);
  border-radius: 0.375rem;
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  display: flex;
  gap: 10px;
  align-items: center;
  color: var(--main-text-color);
}

.nav-menu > ul > li > a:hover,
.config-action .btn-config.active,
.nav-menu .active,
.config-action .btn-config:hover {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.Header_container .config-action {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.config-action .btn-config {
  border-radius: 50%;
  padding: 0.625rem;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
}

.config-action .btn-config.mobile-action {
  display: none;
}

.Header_container .select-language {
  height: 40px;
  padding-left: 5px;
  background-color: transparent;
  font-weight: 500;
  color: var(--main-text-color);
}

.Header_container .select-language option {
  color: var(--main-text-color);
  background-color: var(--btn-menu-bg);
}

.Header_container .select-language:focus,
.Header_container .select-language:focus-visible {
  border: none;
  background-color: transparent;
}

.Header_container.hd-fixed-top {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  transition: background-color 0.2s linear;
}

.Header_container.hd-fixed-top.hd-blur-bg {
  --tw-border-opacity: 0.8;
  --tw-bg-opacity: 0.5;
  --tw-backdrop-blur: blur(4px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur);
  backdrop-filter: var(--tw-backdrop-blur);
  background-color: rgb(var(--bg-filter-blur-1) / var(--tw-bg-opacity));
  border-bottom-color: rgb(
    var(--border-filter-blur-1) / var(--tw-border-opacity)
  );
  border-bottom-width: 1px;
}

/* xl */
@media only screen and (min-width: 1200px) {
}

/* lg */
@media only screen and (max-width: 992px) {
  .Header_container .nav-menu,
  .Header_container.hd-blur-bg .nav-menu {
    position: absolute;
    top: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    --tw-border-opacity: 0.8;
    --tw-bg-opacity: 1;
    --tw-backdrop-blur: blur(4px);
    -webkit-backdrop-filter: var(--tw-backdrop-blur);
    backdrop-filter: var(--tw-backdrop-blur);
    background-color: rgb(var(--bg-filter-blur-1) / var(--tw-bg-opacity));
    filter: drop-shadow(0 10px 8px rgba(0, 0, 0, 0.04))
      drop-shadow(0 4px 3px rgba(0, 0, 0, 0.1));
    padding-bottom: 1rem;
    padding-top: 1rem;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    height: auto;
    overflow: hidden;
  }
  .Header_container .nav-menu > ul {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .Header_container .nav-menu > ul a {
    background-image: none;
    color: var(--main-text-color);
    background-color: transparent;
  }
  .Header_container .nav-menu > ul a.active,
  .Header_container .nav-menu > ul a:hover,
  .Header_container .nav-menu > ul a:focus {
    background-image: none;
    color: var(--tw-gradient-to);
    background-color: transparent;
  }

  .Header_container .nav-menu.hide {
    height: 0px !important;
    padding: 0;
  }

  .Header_container .config-action {
    padding: 0px 10px;
    flex: 1 1 auto;
    justify-content: flex-end;
  }
  .Header_container .config-action .btn-config {
    margin: 0;
    margin-left: 0.525rem;
  }
  .Header_container .config-action .btn-config.active {
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }
  .config-action .btn-config.mobile-action {
    display: block;
  }
}
/* md */
@media only screen and (max-width: 768px) {
  .Header_container {
    padding: 0px;
  }
}

/* sm */
@media only screen and (max-width: 576px) {
  .Header_container {
    padding: 0px;
  }
}
